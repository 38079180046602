.App {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
}

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.splitScreen {
	display: flex;
	flex-direction: row;
}

.leftPane {
	width: 35%;
}

.rightPane {
	width: 65%;
}

.middleColumn {
	display: flex;
	align-items: left;
	justify-content: center;
	flex-direction: column;
	padding-top: 20%;
}

.search {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 5px;
	padding: 20px;
	background-color: #282c34;
}

.response {
	display: flex;
	align-items: left;
	justify-content: left;
	flex-direction: column;
	margin-left: 110px;
	gap: 5px;
}

.trackingButton {
	margin-left: 5px;
}

.card {
	color: #467cf3;
	padding-bottom: 3px;
	font-size: 25px;
	font-weight: 500;
}

.deviceNameInput {
	width: 500px;
	height: 30px;
	border-radius: 5px;
	border: 1px solid #282c34;
	padding: 5px;
}

@media only screen and (max-width: 600px) {
	.rightPane {
		width: 100%;
		height: 50%;
	}

	.leftPane {
		width: 1%;
		height: 50%;
		z-index: 1000;
		background-color: #282c34;
	}

	.response {
		width: 50%;
	}

	.dashboard {
		color: #467cf3;
	}

	.card {
		color: #467cf3;
		position: relative;
		top: 2rem;
		font-size: 25px;
		font-weight: 500;
		width: max-content;
		background-color: #282c34;
	}

	.deviceNameInput {
		position: relative;
		width: 10rem;
		height: 30px;
		padding: 20px;
	}

	.search {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		position: relative;
		top: 1rem;
		width: 80%;
		gap: 5px;
		left: 50px;
		width: max-content;
		background-color: #282c34;
	}
}
